import React, { useEffect, useState } from "react";
import { Button, SecondaryHeader, useChecklist } from "vapi-ui-common";
import { ChecklistItem } from "vapi-ui-common/dist/src/hooks/useChecklist/useChecklist";
import AccessoryFilters from "../components/AccessoryFilters";
import { AppliedFilters } from "../components/AccessoryFilters/AccessoryFilters";
import { FilterTab, FilterTabColumns } from "../components/FilterTab";
import FilterTabFooter from "../components/FilterTab/FilterTabFooter";
import ModelFilters from "../components/ModelFilters";
import { RefItem } from "../gql/generated";
import VehicleModelsStore, { ModelItem } from "../stores/modelStore";
import styles from "./useAccessoryFilter.module.scss";

const useAccessoryFilter = (
  modelStore: VehicleModelsStore,
  isActiveFilter: boolean,
  inProgressFilter: boolean,
  hasDioFilter: boolean | undefined,
  hasPpoFilter: boolean | undefined,
  syncChangesFilter: boolean | undefined,
  productTypes: RefItem[] | undefined,
  productTypeFilters: string[] | undefined,
  isSpanish: boolean | undefined,
  disableDio: boolean | undefined,
  disablePpo: boolean | undefined,
  onApplyFilters: (filters: AppliedFilters) => void
) => {
  enum FILTERTYPE {
    ACCESSORIES = "Accessories",
    MODELMATRIX = "Model Matrix",
  }

  const [selectedFilterTab, setSelectedFilterTab] = useState<string>(
    FILTERTYPE.ACCESSORIES
  );

  const [modelItemList, setModelItemlist] = useState<ModelItem[]>([]);

  const [selectedFuelTypes, setSelectedFuelTypes] = useState<string[]>([]);
  const [savedFuelTypes, setSavedFuelTypes] = useState<string[]>([]);
  const [inProgress, setInProgress] = useState(inProgressFilter);
  const [savedInProgress, setSavedInProgress] = useState(inProgressFilter);
  const [syncChanges, setSyncChanges] = useState(syncChangesFilter || false);
  const [active, setActive] = useState(isActiveFilter);
  const [savedActive, setSavedActive] = useState(isActiveFilter);
  const [dio, setDio] = useState(hasDioFilter);
  const [savedDio, setSavedDio] = useState(hasDioFilter);
  const [ppo, setPpo] = useState(hasPpoFilter);
  const [savedPpo, setSavedPpo] = useState(hasPpoFilter);
  const [savedChecklist, setSavedChecklist] = useState<ChecklistItem[]>([]);

  const { checklist, setChecklist, isAllSelected, selectAll, selectItem } =
    useChecklist();

  useEffect(() => {
    setModelItemlist(modelStore.modelItems);
  }, [modelStore, modelStore.modelItems]);

  useEffect(() => {
    (() => {
      if (productTypeFilters && productTypes) {
        setChecklist(
          productTypes.map((item: RefItem) => ({
            id: item.id,
            name: item.name,
            selected: productTypeFilters.indexOf(item.id) !== -1,
          }))
        );
      }
    })();
  }, [productTypes, setChecklist, productTypeFilters]);

  const onApplyModelFilters = (fuelTypes: string[]) => {
    const modelDir: Record<
      string,
      Record<string, any>
    > = modelStore.data.reduce((acc, model) => {
      if (!acc[model.fuelType]) {
        acc[model.fuelType] = {};
      }
      acc[model.fuelType][model.id] = model;
      return acc;
    }, {} as Record<string, Record<string, any>>);

    let filteredModels = modelStore.modelItems;

    if (fuelTypes.length) {
      filteredModels = filteredModels.filter((item) => {
        let found = false;

        fuelTypes.forEach((fuelType) => {
          if (found) {
            return false;
          }

          if (modelDir[fuelType][item.modelId]) {
            found = true;
          }

          return found;
        });

        return found;
      });
    }

    setSavedFuelTypes(fuelTypes);
    setModelItemlist(filteredModels);
  };

  const getFilterDisplay = (onClose: () => void) => {
    const applyFilters = () => {
      const productFilters: string[] = [];
      checklist.forEach((item) => {
        if (item.selected) {
          productFilters.push(item.id);
        }
      });
      onApplyFilters({
        productFilters,
        inProgress,
        syncChanges,
        active,
        dio,
        ppo,
      });
      setSavedChecklist(checklist);
      setSavedDio(dio);
      setSavedPpo(ppo);
      setSavedActive(active);
      setSavedInProgress(inProgress);
      onClose();
    };

    const onClick = () => {
      applyFilters();
      onApplyModelFilters(selectedFuelTypes);
      onClose();
    };

    const onCancel = () => {
      setChecklist(savedChecklist);
      setSelectedFuelTypes(savedFuelTypes);
      setDio(savedDio);
      setPpo(savedPpo);
      setActive(savedActive);
      setInProgress(savedInProgress);
      onClose();
    };

    return (
      <div className={styles.filterContainer}>
        <SecondaryHeader
          tabs={[FILTERTYPE.ACCESSORIES, FILTERTYPE.MODELMATRIX]}
          selectedTab={selectedFilterTab}
          setSelectedTab={setSelectedFilterTab}
          renderButtons={() => <></>}
          className={styles.secondaryHeader}
        />
        <FilterTab>
          <FilterTabColumns>
            {selectedFilterTab === FILTERTYPE.ACCESSORIES && (
              <AccessoryFilters
                checklist={checklist}
                isAllSelected={isAllSelected}
                isSpanish={isSpanish}
                disableDio={disableDio}
                disablePpo={disablePpo}
                selectAll={selectAll}
                selectItem={selectItem}
                inProgress={inProgress}
                setInProgress={setInProgress}
                active={active}
                setActive={setActive}
                dio={dio}
                setDio={setDio}
                ppo={ppo}
                setPpo={setPpo}
                syncChanges={syncChanges}
                setSyncChanges={setSyncChanges}
              />
            )}
            {selectedFilterTab === FILTERTYPE.MODELMATRIX && (
              <ModelFilters
                fuelTypes={modelStore.fuelTypes}
                selectedFuelTypes={selectedFuelTypes}
                setSelectedFuelTypes={setSelectedFuelTypes}
              />
            )}
          </FilterTabColumns>
          <FilterTabFooter>
            <Button variant="transparent" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onClick}>
              Apply Filters
            </Button>
          </FilterTabFooter>
        </FilterTab>
      </div>
    );
  };

  return {
    getFilterDisplay,
    modelItemList,
  };
};

export default useAccessoryFilter;